import { createContext, useContext } from "solid-js";
import type { Accessor, Setter } from "solid-js";
import type { Store } from "solid-js/store";
import type {
  StorageObject,
  StorageSetter,
  RomaUserSessionData,
} from "./types";
import type { TrackHandler } from "@solid-primitives/analytics";
import { AccessorWithLatest } from "@solidjs/router";
import { RomaSessionData } from "~/services/session/session";
import { Permission } from "~/services/roma-api/account/types";
import { FavouriteTypeOption } from "~/services/roma-api/favourites/types";

type SiteCtx = {
  headerVisible: [Accessor<boolean>, Accessor<boolean>, Setter<boolean>];
  isLoading: () => boolean;
  breakpoints: Breakpoints;
  global?: Store<any>;
  track: TrackHandler;
  //? adding trackFBQ ? ask dave..
  trackFBQ: (...args: any) => void;
  // toggleFavourite: (sku: string, type: string) => void; //-> moved to session
  VERSION?: string;
  isDarkMode?: Accessor<boolean>;
  setIsDarkMode?: Setter<boolean>;
  // session?: StorageObject<string>;
  // setSession?: StorageSetter<string, RomaSessionData>;
  // clearSession?: () => void;
  // addToRecentlyViewed?: (sku: string, type: string) => undefined;
  // isPartner?: Accessor<boolean>;
  // measurementUnit?: Accessor<string>;
  // setMeasurementUnit?: Setter<string>;
  // addToRecentlySearched?: (value: string) => undefined;
  // clearRecentlySearched?: () => void;
  // accountStatus?: Resource<Record<string, string | string[]>>;
  // hasPermission?: (...args: string[])=>boolean;
  //? asyncHasPermission?: (...args: string[])=>Promise<boolean>;
};

export const SiteContext = createContext<SiteCtx>();

export const useSiteContext = () => {
  const ctx = useContext(SiteContext);
  if (!ctx) {
    throw new Error(
      "useSiteContext: cannot find SiteContext. Make sure component is wrapped in SiteContextProvider"
    );
  }
  return ctx;
};

type SessionCtx = {
  // session: StorageObject<string>;
  session: AccessorWithLatest<RomaSessionData | null | undefined>;
  // setSession: StorageSetter<string, RomaUserSessionData>;
  setSession: (data: Partial<RomaSessionData>) => Promise<never>;
  clearSession: () => void;
  isPartner: Accessor<boolean | undefined>;
  addToRecentlySearched: (value: string) => void;
  clearRecentlySearched: () => void;
  // hasPermission: (...args: string[]) => boolean;
  hasPermission: (
    permissions: Permission | Permission[],
    options?: { deferStream: boolean }
  ) => AccessorWithLatest<boolean | undefined>;
  // testSetSession: (values : Record<string,any>)=>Promise<void>;
  // testIsPartner: AccessorWithLatest<boolean | undefined>;
  toggleFav: (sku: string, type: FavouriteTypeOption) => Promise<void>;
  isFavourited: (sku: string, type: FavouriteTypeOption) => boolean;
};

export const SessionContext = createContext<SessionCtx>();

export const useSessionContext = () => {
  const ctx = useContext(SessionContext);
  if (!ctx) {
    throw new Error(
      "useSessionContext: cannot find SessionContext. Make sure component is wrapped in SessionContextProvider"
    );
  }
  return ctx;
};

type Breakpoints = {
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  xxl: boolean;
};

type BuilderGlobals = {};
